import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { editTrackStatuses } from '../../../../../constants/editTrackStatuses';

const menuItemStyles = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '1.6rem',
  color: '#E0E0E0',
  padding: '12px 16px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
  },
};

export default function ActionsMenu({
  openUpdateTrackPopup,
  openRemoveTrackPopup,
  openAddBeforeTrackPopup,
  openAddAfterTrackPopup,
  openUndoEditPopup,
  isDisabled,
  trackStatus,
  isPreviousTrack,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip
        title={isDisabled ? 'Only the owner of the event can edit it.' : ''}
        disableHoverListener={!isDisabled}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: '11px',
              backgroundColor: '#2D323E',
              color: '#fff',
              lineHeight: '16px',
              opacity: '0.6',
              padding: '4px 8px',
            },
          },
        }}
      >
        <Box width="2.4rem">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'actions-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            disableRipple
            disabled={isDisabled}
            sx={{ padding: 0 }}
          >
            <MoreVertIcon
              sx={{
                color: open ? '#E0E0E0' : '#757575',
                fontSize: '2.4rem',
                backgroundColor: open ? 'rgba(16, 20, 29, 0.4)' : 'transparent',
                borderRadius: '4px',

                '&:hover': {
                  backgroundColor: 'rgba(16, 20, 29, 0.4)',
                  color: '#E0E0E0',
                },
              }}
            />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-menu',
          sx: {
            padding: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: '17.1rem',
              border: '1px solid rgba(224, 224, 224, 0.74)',
              backgroundColor: '#1B2130',
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {trackStatus !== editTrackStatuses.REMOVED &&
          trackStatus !== editTrackStatuses.UPDATED &&
          trackStatus !== editTrackStatuses.ADDED && (
            <MenuItem
              onClick={openUpdateTrackPopup}
              sx={menuItemStyles}
              disableRipple
            >
              Update track
            </MenuItem>
          )}

        {trackStatus === editTrackStatuses.REMOVED ||
        trackStatus === editTrackStatuses.UPDATED ? (
          <MenuItem
            onClick={openUndoEditPopup}
            sx={menuItemStyles}
            disableRipple
          >
            {trackStatus === editTrackStatuses.REMOVED
              ? 'Undo delete'
              : 'Restore track'}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={
              trackStatus === editTrackStatuses.ADDED
                ? openUndoEditPopup
                : openRemoveTrackPopup
            }
            sx={menuItemStyles}
            disableRipple
          >
            {trackStatus === editTrackStatuses.ADDED
              ? 'Remove added track'
              : 'Remove track'}
          </MenuItem>
        )}

        {trackStatus === editTrackStatuses.UPDATED && isPreviousTrack ? (
          // Show "Add missing track before" menu item when trackStatus is "UPDATED" and isPreviousTrack is true
          <MenuItem
            onClick={openAddBeforeTrackPopup}
            sx={menuItemStyles}
            disableRipple
          >
            Add missing track before
          </MenuItem>
        ) : (
          <>
            {/* Show both menu items if isPreviousTrack is false and trackStatus is not "UPDATED" */}
            {!isPreviousTrack && trackStatus !== editTrackStatuses.UPDATED && (
              <>
                <MenuItem
                  onClick={openAddBeforeTrackPopup}
                  sx={menuItemStyles}
                  disableRipple
                >
                  Add missing track before
                </MenuItem>
                <MenuItem
                  onClick={openAddAfterTrackPopup}
                  sx={menuItemStyles}
                  disableRipple
                >
                  Add missing track after
                </MenuItem>
              </>
            )}
            {/* Show only "Add missing track before" if isPreviousTrack is false and trackStatus is "UPDATED" */}
            {!isPreviousTrack && trackStatus === editTrackStatuses.UPDATED && (
              <MenuItem
                onClick={openAddAfterTrackPopup}
                sx={menuItemStyles}
                disableRipple
              >
                Add missing track after
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </>
  );
}
