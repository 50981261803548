import { createContext, useState, useContext } from 'react';

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [serverError, setServerError] = useState('');

  const triggerError = (errorMessage) => {
    setServerError(errorMessage);
  };

  return (
    <ErrorContext.Provider
      value={{ serverError, triggerError, setServerError }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
