import React from 'react';

import {
  TextField,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const AddCustomTrackForm = ({ onSubmit, addedTrack, setAddedTrack }) => {
  const handleTitleChange = (e) => {
    setAddedTrack((prevTrack) => ({
      ...prevTrack,
      title: e.target.value,
    }));
  };

  const handleArtistsChange = (e) => {
    setAddedTrack((prevTrack) => ({
      ...prevTrack,
      artists: e.target.value,
    }));
  };

  const handleClear = (field) => {
    setAddedTrack((prevTrack) => ({
      ...prevTrack,
      [field]: '',
    }));
    document.getElementById(field).blur(); // Unfocus the field
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(addedTrack); // Submit the updated addedTrack data
  };

  const getTextFieldStyles = (isFilled) => ({
    '& .MuiOutlinedInput-root': {
      fontSize: '1.2rem',
      fontWeight: 400,
      '& fieldset': {
        borderColor: isFilled ? '#E0E0E0' : '#757575',
      },
      '&:hover fieldset': {
        borderColor: isFilled ? '#E0E0E0' : '#757575',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E0E0E0',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 16px',
      lineHeight: '16px',
      height: 'inherit',
    },
  });

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '3.3rem',
        margin: '0 auto',
      }}
      onSubmit={handleSubmit}
    >
      <Box>
        <Typography
          variant="body1"
          component="label"
          htmlFor="title"
          display="flex"
          align="left"
          mb={'0.8rem'}
          fontSize="1.4rem"
          fontWeight={500}
        >
          Title
        </Typography>
        <TextField
          id="title"
          variant="outlined"
          fullWidth
          required
          value={addedTrack.title}
          onChange={handleTitleChange}
          sx={getTextFieldStyles(Boolean(addedTrack.title))}
          InputProps={{
            endAdornment: addedTrack.title && (
              <InputAdornment position="end">
                <IconButton onClick={() => handleClear('title')} edge="end">
                  <ClearIcon
                    sx={{
                      color: '#E0E0E0',
                      width: '2.4rem',
                      height: '2.4rem',
                      fontSize: '12px',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box>
        <Typography
          variant="body1"
          component="label"
          htmlFor="artists"
          display="flex"
          align="left"
          mb={'0.8rem'}
          fontSize="1.4rem"
          fontWeight={500}
        >
          Artists (Separate with commas)
        </Typography>
        <TextField
          id="artists"
          variant="outlined"
          fullWidth
          required
          value={addedTrack.artists}
          onChange={handleArtistsChange}
          sx={getTextFieldStyles(Boolean(addedTrack.artists))}
          InputProps={{
            endAdornment: addedTrack.artists && (
              <InputAdornment position="end">
                <IconButton onClick={() => handleClear('artists')} edge="end">
                  <ClearIcon
                    sx={{
                      color: '#E0E0E0',
                      width: '2.4rem',
                      height: '2.4rem',
                      fontSize: '12px',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default AddCustomTrackForm;
