import { Box, Snackbar } from '@mui/material';
import { useError } from '@context/ErrorContext';

const ServerErrorAlert = () => {
  const { serverError, setServerError } = useError();

  return (
    <Box className="error-alert">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={10000}
        open={!!serverError}
        onClose={() => setServerError('')}
        message={serverError}
      />
    </Box>
  );
};

export default ServerErrorAlert;
