import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Container, Grid, Button, Tooltip } from '@mui/material';

import { useDeviceDashboardData, exportEventsData } from '@apis';
import { useDetectOutsideClick } from '@hooks';
import {
  MIN_DATE_RANGE,
  DATA_ERRORS,
  FILTERS,
  FILTERS_CUSTOM_DATE,
} from '@configs';
import {
  getDateDifference,
  getStartOfMonth,
  getFilenameFromContentDisposition,
  downloadBlob,
} from '@utils';
import { useUser } from '@context/UserContext';
import { LoadingLogo } from '@components';
import { SectionSkeleton } from '@components/Skeletons/SectionSkeleton';
import ServerErrorAlert from './components/v2/ServerErrorAlert';

import MetricCount from './components/v2/MetricCount';
import SectionHeading from './components/v2/SectionHeading';
import TopTracks from './components/v2/TopTracks';
import DashboardHeader, {
  dashboardTypes,
} from './components/v2/DashboardHeader';
import EventsList from './components/v2/EventsList';
import EmptyContent from './components/v2/EmptyContent';
import { updateBreadcrumbs } from './components/v2/DashboardBreadcrumb';
import ActionsPopup from './components/v2/ActionsPopup';
import UploadCustomTrackPopup from './components/v2/UploadCustomTrackPopup';
import CustomTracks from './components/v2/CustomTracks';
import DeleteCustomTrack from './components/v2/DeleteCustomTrack/DeleteCustomTrack';

const getValidDate = (storedValue, fallback) => {
  const timestamp = Number(storedValue);
  const date = new Date(timestamp);
  return isNaN(date.getTime()) || timestamp === 0 ? fallback : date;
};
const INITIAL_DATE = getValidDate(
  localStorage.getItem('start_date'),
  getDateDifference(3)
);

const END_DATE = getValidDate(localStorage.getItem('end_date'), new Date());

export const DeviceDashboard = () => {
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const [showExportPrompt, setShowExportPrompt] = useState(false);
  const [showUploadCustomTrackPopup, setShowUploadCustomTrackPopup] =
    useState(false);
  const [dateRange, setDateRange] = useState([INITIAL_DATE, END_DATE]);
  const [newDateRange, setNewDateRange] = useState([INITIAL_DATE, END_DATE]);

  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const calendarRef = useRef(null);
  const isClickedOutside = useDetectOutsideClick(calendarRef);
  let { id: deviceId } = useParams();
  const { user, isLoading: isLoadingUserInfo } = useUser();

  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [selectedCustomTrackIdToDelete, setSelectedCustomTrackIdToDelete] =
    useState(null);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [activeFilter, setActiveFilter] = useState(
    localStorage.getItem('active_filter_name') || 'Last 3 months'
  );
  const {
    fetchDashboardData,
    isLoadingDashboardData,
    data: dashboardData,
    serverError,
    isRefetching,
    resetStoredDeviceData,
  } = useDeviceDashboardData();

  const { mutateAsync: exportEventsMutate, isPending: isExportEventsLoading } =
    useMutation({
      mutationFn: exportEventsData,
      onSuccess: (response) => {
        let contentType = response.headers?.['content-type'];
        let contentDisposition = response.headers?.['content-disposition'];

        let filename;
        if (contentType === 'text/csv; charset=utf-8') {
          filename =
            getFilenameFromContentDisposition(contentDisposition) ||
            'events.csv';
        } else if (contentType === 'application/zip') {
          filename = 'events.zip';
        } else {
          console.warn('Unexpected content type:', contentType);
          filename = 'downloaded_file'; // Fallback filename
        }

        // Create a blob with the correct content type
        const blob = new Blob([response.data], {
          type: contentType || 'application/octet-stream',
        });

        // Download the file
        downloadBlob(blob, filename);

        setShowExportPrompt(false);
      },
      onError: (error) => {
        console.error('Export failed:', error);
      },
    });

  const dashboard_response_status =
    dashboardData && dashboardData?.responseStatus;
  const dashboard_data = dashboardData && dashboardData?.data;

  useEffect(() => {
    const element = document.getElementById('root');
    if (element) {
      element.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (dashboardData) {
      if (!isLoadingDashboardData) {
        setIsLoadingDashboard(false);
      }
      getLastUpdateTime();
      if (deviceId) {
        const breadcrumbDataWithDeviceName = [
          { key: 1, label: 'Home', href: '/' },
          { key: 2, label: dashboardData?.device_name },
        ];
        updateBreadcrumbs(breadcrumbDataWithDeviceName);
      } else {
        updateBreadcrumbs([{ key: 1, label: 'Home' }]);
      }
      if (DATA_ERRORS.includes(dashboardData.status)) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_access_token');
        navigate('/login');
      }
      return;
    }

    if (serverError) {
      setIsLoadingDashboard(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_response_status, dashboard_data, serverError]);

  useEffect(() => {
    if (!user) return;

    const storedFilter =
      localStorage.getItem('active_filter_name') || 'Last 3 months';

    if (storedFilter !== FILTERS_CUSTOM_DATE) {
      let startDate;
      if (storedFilter === 'This month') {
        startDate = getStartOfMonth();
      } else if (storedFilter === 'Last 3 months') {
        startDate = getDateDifference(3);
      } else if (storedFilter === 'Last 6 months') {
        startDate = getDateDifference(6);
      } else if (storedFilter === 'All Events') {
        startDate = 1704067200000;
      }

      const endDate = new Date();

      // Update state and localStorage to reflect the dynamically recalculated dates
      setDateRange([startDate, endDate]);
      setNewDateRange([startDate, endDate]);

      localStorage.setItem('start_date', new Date(startDate).getTime());
      localStorage.setItem('end_date', endDate.getTime());
      localStorage.setItem('active_filter_name', storedFilter);

      getRefreshedTokenAndUpdateUser(
        new Date(startDate).getTime(),
        endDate.getTime()
      );
    } else {
      // If custom date is selected, rely on previously stored values
      setDateRange([INITIAL_DATE, END_DATE]);
      setNewDateRange([INITIAL_DATE, END_DATE]);
      getRefreshedTokenAndUpdateUser(
        INITIAL_DATE.getTime(),
        END_DATE.getTime()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isClickedOutside && dateRange !== newDateRange) {
      setDateRange(newDateRange);
    }
    if (isClickedOutside) {
      setIsShowCalendar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedOutside]);

  const getLastUpdateTime = () => {
    const d = new Date();
    const updateTime = `${d
      .toDateString()
      .split(' ')
      .slice(1)
      .join(' ')} ${d.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })} `;
    setLastUpdateTime(updateTime);
  };

  const handleToggleCalendar = () => {
    setIsShowCalendar(!isShowCalendar);
  };

  const getRefreshedTokenAndUpdateUser = (startDate, endDate) => {
    onAuthStateChanged(auth, async (authUser) => {
      setIsLoadingDashboard(true);

      const initialStartDate = new Date(newDateRange[0]);
      const initialEndDate = new Date(newDateRange[1]);

      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            user_id: user?.user_id,
            access_token: idToken,

            device_id: deviceId,
            start_date: startDate || initialStartDate?.getTime(),
            end_date: endDate || initialEndDate?.getTime(),
          };
          fetchDashboardData(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const handleExportEvents = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            userId: user?.user_id,
            accessToken: idToken,

            eventIds: selectedEventIds,
          };
          await exportEventsMutate(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const handleSearchBtn = async () => {
    await resetStoredDeviceData();
    setIsShowCalendar(false);
    setNewDateRange(dateRange);

    const start = new Date(dateRange[0]);
    const end = new Date(dateRange[1]);

    const startUTC = Date.UTC(
      start.getFullYear(),
      start.getMonth(),
      start.getDate()
    );

    // Check if the selected end date is today
    const today = new Date();
    const isToday =
      end.getFullYear() === today.getFullYear() &&
      end.getMonth() === today.getMonth() &&
      end.getDate() === today.getDate();

    const endUTC = isToday
      ? Date.now() // If end date is today, use current timestamp
      : Date.UTC(end.getFullYear(), end.getMonth(), end.getDate() + 1); // Start of next day

    localStorage.setItem('start_date', startUTC);
    localStorage.setItem('end_date', endUTC);
    localStorage.setItem('active_filter_name', FILTERS_CUSTOM_DATE);

    setActiveFilter(FILTERS_CUSTOM_DATE);

    await getRefreshedTokenAndUpdateUser(startUTC, endUTC);
  };

  const onChangeDateRange = (value) => {
    setDateRange(value);
  };

  const onClickDateFilters = async (filter) => {
    let startDate = '';
    await resetStoredDeviceData();
    if (filter === 'This month') {
      startDate = getStartOfMonth();
    }

    if (filter === 'Last 3 months') {
      startDate = getDateDifference(3);
    }

    if (filter === 'Last 6 months') {
      startDate = getDateDifference(6);
    }

    if (filter === 'All Events') {
      startDate = 1704067200000;
    }

    setActiveFilter(filter);
    localStorage.setItem('start_date', new Date(startDate).getTime());
    localStorage.setItem('end_date', new Date().getTime());
    localStorage.setItem('active_filter_name', filter);
    setDateRange([startDate, new Date()]);
    setNewDateRange([startDate, new Date()]);

    await getRefreshedTokenAndUpdateUser(
      new Date(startDate).getTime(),
      new Date().getTime()
    );
  };

  const calendarProps = {
    isShowCalendar,
    handleToggleCalendar,
    dateRange,
    onChangeDateRange,
    handleSearchBtn,
    calendarRef,
    MIN_DATE_RANGE,
  };

  const filtersProps = {
    FILTERS,
    activeFilter,
    onClickDateFilters,
  };

  const isLoadingDashboardAndNoData =
    isLoadingDashboard && dashboardData === null;

  return (
    <>
      {(isLoadingDashboardAndNoData || isLoadingUserInfo) && <LoadingLogo />}

      <Box pb="9rem">
        <Container maxWidth="xl">
          <DashboardHeader
            lastUpdateTime={lastUpdateTime}
            calendarProps={calendarProps}
            filtersProps={filtersProps}
            dashboardType={
              deviceId ? dashboardTypes.deviceFromGroup : dashboardTypes.device
            }
            deviceName={dashboardData?.device_name}
            hasFilterOptions
            hasLoading={isLoadingDashboardAndNoData}
          />
          {serverError ? (
            <Box mt="5.4rem" height="100%">
              <ServerErrorAlert message={serverError} />
            </Box>
          ) : (
            <>
              <Box mt={'54px'}>
                {isLoadingDashboardAndNoData && (
                  <SectionSkeleton height="30rem" />
                )}

                {dashboardData?.metrics.object && (
                  <>
                    <SectionHeading
                      headingText={dashboardData?.metrics.title}
                      tooltipText={dashboardData?.metrics.info}
                    />
                    <MetricCount
                      metrics={Object.entries(dashboardData?.metrics.object)
                        .filter(([key, _]) => key.startsWith('object'))
                        .map(([_, value]) => value)}
                      chartData={dashboardData?.metrics.object.chart_data}
                    />
                  </>
                )}
              </Box>
              <Box mt="5rem">
                <Grid container spacing={'4rem'}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    {isLoadingDashboardAndNoData && <SectionSkeleton />}

                    {dashboardData?.section_1 && (
                      <>
                        <SectionHeading
                          headingText={dashboardData?.section_1.title}
                          tooltipText={dashboardData?.section_1.info}
                        />
                        <TopTracks tracks={dashboardData?.section_1.object} />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    {isLoadingDashboardAndNoData && <SectionSkeleton />}

                    {dashboardData?.section_2 && (
                      <>
                        <Box display="flex" justifyContent="space-between">
                          <SectionHeading
                            headingText={dashboardData?.section_2.title}
                            tooltipText={dashboardData?.section_2.info}
                          />
                          <Box>
                            <Tooltip
                              title={
                                deviceId
                                  ? dashboardData?.upload_custom_track_info
                                      .upload_not_user
                                  : ''
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: 'background.tooltip',
                                    color: 'rgba(255, 255, 255, 0.6)',
                                    fontSize: '11px',
                                  },
                                },
                              }}
                            >
                              <Box>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setShowUploadCustomTrackPopup(true);
                                  }}
                                  disabled={deviceId}
                                >
                                  Upload
                                </Button>
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>

                        {dashboardData.section_2.object?.length === 0 ? (
                          <EmptyContent text="There are no custom tracks in the list." />
                        ) : (
                          <CustomTracks
                            tracks={dashboardData.section_2.object}
                            onSelectCustomTrackToDelete={(id) => {
                              setSelectedCustomTrackIdToDelete(id);
                            }}
                            deleteCustomTrackInfoText={
                              deviceId
                                ? dashboardData.upload_custom_track_info
                                    .delete_not_user
                                : ''
                            }
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box mt="5rem">
                <Grid container>
                  <Grid item xs={12} md={12}>
                    {isLoadingDashboardAndNoData && <SectionSkeleton />}

                    {!isLoadingDashboardAndNoData &&
                      dashboardData?.section_3.object && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <SectionHeading
                              headingText={dashboardData?.section_3.title}
                              tooltipText={dashboardData?.section_3.info}
                            />

                            <Box mb="2rem">
                              <Button
                                variant="outlined"
                                onClick={() => setShowExportPrompt(true)}
                                disabled={selectedEventIds.length === 0}
                              >
                                Export
                              </Button>
                            </Box>
                          </Box>
                          <EventsList
                            data={dashboardData?.section_3.object || []}
                            associatedDevice={{
                              deviceUrl: window.location.href,
                              deviceName: dashboardData?.device_name,
                            }}
                            onSelectionEventChange={(ids) => {
                              setSelectedEventIds(ids);
                            }}
                          />
                        </>
                      )}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Box>

      {showExportPrompt && (
        <ActionsPopup
          open={showExportPrompt}
          handleClose={() => setShowExportPrompt(false)}
          handleSubmit={handleExportEvents}
          loading={isExportEventsLoading}
          headingText="Export selected events"
          loadingText="Exporting..."
          submitBtnText="Export"
        />
      )}

      {showUploadCustomTrackPopup && (
        <UploadCustomTrackPopup
          open={showUploadCustomTrackPopup}
          handleClose={() => setShowUploadCustomTrackPopup(false)}
          refetchDashboardData={getRefreshedTokenAndUpdateUser}
          isLoadingRefetch={isRefetching}
        />
      )}

      {selectedCustomTrackIdToDelete && (
        <DeleteCustomTrack
          open={!!selectedCustomTrackIdToDelete}
          handleClose={() => setSelectedCustomTrackIdToDelete(null)}
          customTrackId={selectedCustomTrackIdToDelete}
          refetchDashboardData={getRefreshedTokenAndUpdateUser}
          isLoadingRefetch={isRefetching}
        />
      )}
    </>
  );
};
