import { Box } from '@mui/material';

export const SectionSkeleton = ({ ...boxSxProps }) => {
  return (
    <Box>
      <Box width="12rem" height={'1.5rem'} bgcolor="white" mb="2rem" />
      <Box
        sx={{
          bgcolor: 'background.section',
          color: '#ffffff',
          borderRadius: '8px',
          height: '39rem',
          ...boxSxProps,
        }}
      />
    </Box>
  );
};
