const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={107}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M21.201 11.614 3.281.695A1.477 1.477 0 0 0 2.067.541a1.518 1.518 0 0 0-.963 2.014c.117.293.327.54.597.705l4.014 2.456.937.573 3.003 1.837 7.7 4.723a.067.067 0 0 1 0 .115l-11.419 6.7a.064.064 0 0 1-.08-.004.064.064 0 0 1-.013-.079l2.522-6.117a1.378 1.378 0 0 0 .003-1.07L6.763 8.331a.087.087 0 0 0-.026-.032l-3.94-2.408a.177.177 0 0 0-.253.215l2.73 6.793a.07.07 0 0 1 0 .051l-4.17 9.939a1.509 1.509 0 0 0 2.168 1.875l17.952-10.58a1.517 1.517 0 0 0 .738-1.282 1.49 1.49 0 0 0-.76-1.289Z"
      />
      <path
        fill="#fff"
        d="M44.172 8.61 38.5 21.822h-3.183L29.645 8.61h3.208l4.11 9.798 4.235-9.798h2.974ZM44.988 15.215c0-3.956 2.971-6.752 7.033-6.752s7.059 2.796 7.059 6.752-2.949 6.78-7.059 6.78-7.033-2.821-7.033-6.78Zm10.97 0c0-2.523-1.684-4.13-3.937-4.13-2.252 0-3.91 1.603-3.91 4.13 0 2.526 1.684 4.132 3.91 4.132 2.227 0 3.937-1.606 3.937-4.132ZM62.397 3.465h3.097v18.357h-3.097V3.465ZM69.898 3.465h3.098v18.357h-3.098V3.465ZM76.31 15.215c0-3.956 2.972-6.752 7.034-6.752 4.062 0 7.058 2.796 7.058 6.752s-2.945 6.78-7.058 6.78c-4.114 0-7.033-2.821-7.033-6.78Zm10.97 0c0-2.523-1.684-4.13-3.936-4.13-2.253 0-3.915 1.603-3.915 4.13 0 2.526 1.685 4.132 3.915 4.132s3.95-1.606 3.95-4.132h-.014ZM103.428 8.61v6.682c0 2.644-1.46 3.981-3.616 3.981-1.957 0-3.096-1.138-3.096-3.526V8.61h-3.093v7.547c0 3.206 1.502 5.043 3.85 5.63.777.253 1.823.35 3.34.208 3.379-.32 5.699-1.856 5.699-5.838V8.61h-3.084Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={0.949}
        x2={21.926}
        y1={12.727}
        y2={12.727}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4783B" />
        <stop offset={1} stopColor="#ED1C24" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M.95.467h105.574V24.99H.95z" />
      </clipPath>
    </defs>
  </svg>
);
export default Logo;
