import React, { useState, useEffect } from 'react';
import { Box, Avatar, Container } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useError } from '@context/ErrorContext';

import { Modal } from '../Modal';
import { Button } from '../Button';
import Logo from '@components/Logo';
import './index.css';

export const Header = ({ name, image, hasLoading }) => {
  const authToken = localStorage.getItem('user_access_token');
  const userId = localStorage.getItem('user_id');
  const auth = getAuth();
  const navigate = useNavigate();
  const { triggerError } = useError();

  const [openModal, setOpenModal] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        localStorage.clear();
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const onClose = () => {
    if (!isLoggingOut) {
      setOpenModal(false);
    }
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      const unsubscribe = onAuthStateChanged(auth, () => {});
      await auth.signOut();
      unsubscribe();
      localStorage.clear();
      triggerError('');
      navigate('/login');
      window.location.reload(); // Refresh the application to reset all states
    } catch (err) {
      triggerError('Failed to logout. Please try again.');
    } finally {
      setIsLoggingOut(false);
      setOpenModal(false);
    }
  };

  return (
    <>
      <Box bgcolor={'#0F0F0F'}>
        <Container maxWidth="xl">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minHeight="9.6rem"
          >
            <Box
              className="admin-logo-wrapper"
              component={'a'}
              href="https://vollou.com/"
            >
              <Logo />
            </Box>

            {authToken && userId && (
              <Box>
                <Box
                  sx={{
                    flexGrow: 0,
                    display: { md: 'flex' },
                    alignItems: 'center',
                  }}
                >
                  <Box className="header-avatar-wrapper">
                    <Avatar
                      alt="User Avatar"
                      src={image}
                      className="header-avatar"
                    />
                    {hasLoading ? (
                      <Box width={90} height={10} bgcolor={'white'} />
                    ) : (
                      <p className="header-username">{name}</p>
                    )}
                  </Box>
                  <Button
                    variant="secondary"
                    onClick={() => setOpenModal(true)}
                  >
                    Logout
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>

      <Modal
        open={openModal}
        onClick={handleLogout}
        onClose={onClose}
        isLogoutModal
        label="Logout"
        title="Are you sure you want to logout?"
        loading={isLoggingOut}
      />
    </>
  );
};
