import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useMutation } from '@tanstack/react-query';
import { Box } from '@mui/material';

import { useUser } from '@context/UserContext';
import { useError } from '@context/ErrorContext';

import { addTrackData } from '@apis';

import ActionsPopup from '../ActionsPopup';
import SearchTrack from './SearchTrack';
import AddCustomTrackForm from './AddCustomTrackForm';
import TrackItem from './TrackItem';

const AddTrackBeforeAfter = ({
  open,
  handleClose,
  selectedTrack,
  eventId,
  refetchDashboard,
}) => {
  const [isShowAddCustomTrack, setIsShowAddCustomTrack] = useState(false);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [addedCustomTrack, setAddedCustomTrack] = useState({
    title: '',
    artists: '',
  });
  const [addedFoundTrack, setAddedFoundTrack] = useState({
    title: '',
    artists: '',
    coverImage: '',
    trackId: '',
  });

  const auth = getAuth();
  const { user } = useUser();
  const { triggerError } = useError();

  // Store token in local state for use in the submission
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          setAuthToken(idToken);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        setAuthToken(null);
      }
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [auth]);

  const { mutate: addTrackMutate, isPending: isAdding } = useMutation({
    mutationFn: addTrackData,
    onSuccess: (response) => {
      if (response && response.status === 200) {
        refetchDashboard();
        handleClose();
      } else {
        console.error({ response });
        triggerError(response.data.status);
      }
    },
    onError: (error) => {
      triggerError('An unexpected server error occurred! - ' + error.message);
    },
  });

  const invalidAddedCustomTrack =
    addedCustomTrack.title === '' || addedCustomTrack.artists === '';
  const shouldDisplayCustomTrack =
    !invalidAddedCustomTrack && !isShowAddCustomTrack && isAddButtonClicked;
  const shouldDisplayFoundTrack =
    addedFoundTrack.title !== '' &&
    addedFoundTrack.artists !== '' &&
    !isShowAddCustomTrack;

  const renderTrackItem = (title, artist, coverImage, timeRecorded) => (
    <Box mt="3.3rem">
      <TrackItem
        trackTypeInfo="New Track"
        title={title}
        artist={artist}
        coverImage={coverImage}
        duration={timeRecorded}
      />
    </Box>
  );

  const handleSubmitAddTrack = async () => {
    try {
      if (!authToken) {
        throw new Error('No access token found');
      }

      const hasCustomTrack = addedCustomTrack.title !== '';

      const params = {
        userId: user?.user_id,
        accessToken: authToken,
        eventId,
        newTimeRecorded: selectedTrack.newTimeRecorded,
        trackId: hasCustomTrack ? null : addedFoundTrack.trackId,
        customTrack: hasCustomTrack
          ? {
              title: addedCustomTrack.title,
              artist: addedCustomTrack.artists,
            }
          : null,
      };

      await addTrackMutate(params);
    } catch (error) {
      console.error('Error submitting track:', error);
      triggerError('An unexpected error occurred!');
    }
  };

  const onAddButtonSubmit = () => {
    setIsShowAddCustomTrack(false);
    setIsAddButtonClicked(true);
    setAddedFoundTrack({ title: '', artists: '', coverImage: '', trackId: '' });
  };

  const isDisabledSubmit = () => {
    if (isShowAddCustomTrack) {
      return addedCustomTrack.title === '' || addedCustomTrack.artists === '';
    } else {
      if (isAddButtonClicked) {
        return addedCustomTrack.title === '' || addedCustomTrack.artists === '';
      } else {
        return addedFoundTrack.title === '' || addedFoundTrack.artists === '';
      }
    }
  };

  useEffect(() => {
    if (!open) {
      // Reset states when modal closes
      setAddedCustomTrack({ title: '', artists: '' });
      setAddedFoundTrack({
        title: '',
        artists: '',
        coverImage: '',
        trackId: '',
      });
      setIsShowAddCustomTrack(false);
      setIsAddButtonClicked(false);
    }
  }, [open]);

  return (
    <ActionsPopup
      open={open}
      handleClose={() => {
        if (isShowAddCustomTrack) {
          setIsShowAddCustomTrack(false);
        } else {
          setAddedFoundTrack({
            title: '',
            artists: '',
            coverImage: '',
            trackId: '',
          });
          setAddedCustomTrack({ title: '', artists: '' });
          handleClose();
        }
      }}
      handleSubmit={() => {
        if (isShowAddCustomTrack) {
          onAddButtonSubmit();
        } else {
          handleSubmitAddTrack();
        }
      }}
      loading={isAdding}
      headingText={'Add Track'}
      loadingText="Adding..."
      submitBtnText={'Add'}
      disabledSubmit={isDisabledSubmit()}
    >
      {isShowAddCustomTrack ? (
        <AddCustomTrackForm
          addedTrack={addedCustomTrack}
          setAddedTrack={setAddedCustomTrack}
        />
      ) : (
        <SearchTrack
          onClickAddCustomTrack={() => setIsShowAddCustomTrack(true)}
          updateFoundTrack={(track) => {
            setAddedFoundTrack({
              title: track.title,
              artists: track.artists,
              coverImage: track.coverImage,
              trackId: track.trackId,
            });
            setAddedCustomTrack({ title: '', artists: '' });
            setIsAddButtonClicked(false);
          }}
        />
      )}
      <>
        {shouldDisplayCustomTrack &&
          renderTrackItem(
            addedCustomTrack.title,
            addedCustomTrack.artists,
            null,
            selectedTrack.newTimeRecorded
          )}
        {shouldDisplayFoundTrack &&
          renderTrackItem(
            addedFoundTrack.title,
            addedFoundTrack.artists,
            addedFoundTrack.coverImage,
            selectedTrack.newTimeRecorded
          )}
        {!shouldDisplayFoundTrack &&
          !shouldDisplayCustomTrack &&
          renderTrackItem('-', '-', '-', selectedTrack.newTimeRecorded)}
      </>
    </ActionsPopup>
  );
};

export default AddTrackBeforeAfter;
