import { Box, Typography } from '@mui/material';

import Tooltip from '../Tooltip';

const SectionHeading = ({ headingText, tooltipText, wrapperStyles }) => {
  return (
    <Box display="flex" alignItems="center" mb="20px" sx={{ ...wrapperStyles }}>
      <Typography variant="h2">{headingText}</Typography>
      <Tooltip title={tooltipText} />
    </Box>
  );
};

export default SectionHeading;
