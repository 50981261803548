import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

export const updateTrackData = async ({
  userId,
  accessToken,
  eventId,
  currentTimeRecorded,
  newTrackId,
  currentTrackId,
  customTrack,
}) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.UPDATE_TRACK_DATA.API,
    {
      user_id: userId,
      access_token: accessToken,
      event_id: eventId,
      current_time_recorded: currentTimeRecorded,
      current_track_id: currentTrackId,
      new_track_id: newTrackId,
      custom_track: customTrack
        ? { title: customTrack.title, artist: customTrack.artists }
        : null,
    }
  );
  return response;
};
