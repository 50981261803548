import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useMutation } from '@tanstack/react-query';
import { Typography } from '@mui/material';

import { useUser } from '@context/UserContext';
import { useError } from '@context/ErrorContext';

import { deleteCustomTrackData } from '@apis';

import ActionsPopup from '../ActionsPopup';
import { useEffect } from 'react';

const DeleteCustomTrack = ({
  open,
  handleClose,
  customTrackId,
  refetchDashboardData,
  isLoadingRefetch,
}) => {
  const { user } = useUser();
  const auth = getAuth();
  const { triggerError } = useError();

  const {
    mutate: deleteCustomTrack,
    isPending: isLoadingDelete,
    data,
  } = useMutation({
    mutationFn: deleteCustomTrackData,
    onSuccess: (response) => {
      if (
        response &&
        response.data &&
        response.status === 200 &&
        !isLoadingRefetch
      ) {
        refetchDashboardData();
      } else {
        triggerError(response.data.status);
      }
    },

    onError: (error) => {
      triggerError('An unexpected server error occurred! - ' + error.message);
    },
  });

  useEffect(() => {
    if (!isLoadingRefetch && data?.status === 200) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRefetch, data]);

  const handleSubmitRemoveTrack = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            userId: user?.user_id,
            accessToken: idToken,
            customTrackId,
          };
          await deleteCustomTrack(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
      }
    });
  };
  return (
    <ActionsPopup
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmitRemoveTrack}
      loading={isLoadingDelete || isLoadingRefetch}
      headingText={'Delete Custom Track'}
      loadingText="Deleting..."
      submitBtnText={'Delete Track'}
    >
      <Typography textAlign="center" fontSize="14px" fontWeight={500}>
        Are you sure, this cannot be undone?
      </Typography>
    </ActionsPopup>
  );
};

export default DeleteCustomTrack;
