import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip from MUI
import ReactMarkdown from 'react-markdown';
import CustomTooltip from '../Tooltip'; // Keep the existing custom Tooltip
import ToggleButton from '../ToggleButton';

export default function ShowEditsSwitchButton({
  tooltipInfo,
  isShow,
  setIsShow,
  isDisabled,
  toggleBtnTooltipText,
}) {
  return (
    <>
      <FormGroup sx={{ opacity: !isDisabled ? 1 : '0.4' }}>
        <Tooltip
          title={toggleBtnTooltipText}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'background.tooltip',
                color: 'rgba(255, 255, 255, 0.6)',
                fontSize: '11px',
              },
            },
          }}
        >
          <FormControlLabel
            disabled={isDisabled}
            control={
              <ToggleButton
                checked={isShow}
                onChange={setIsShow}
                name="checked"
                isShow={isShow}
              />
            }
            label={
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '1.4rem',
                  color: '#FFFFFF',
                  marginRight: '0.8rem',
                }}
              >
                Show edits
              </Typography>
            }
            sx={{ marginRight: 0, marginLeft: 0 }}
            labelPlacement="start"
          />
        </Tooltip>
      </FormGroup>
      <CustomTooltip
        title={
          <Box
            sx={{
              padding: '5px',
              '& ul': {
                margin: 0,
                padding: '0 16px',
              },
              '& li': {
                margin: 0,
                padding: 0,
              },
              '& p': {
                margin: '5px',
                padding: 0,
              },
            }}
          >
            <ReactMarkdown>
              {tooltipInfo.replace(/<br\s*\/?>/g, '  \n')}
            </ReactMarkdown>
          </Box>
        }
      />
    </>
  );
}
