import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Typography } from '@mui/material';

import { useUser } from '@context/UserContext';
import { useError } from '@context/ErrorContext';

import ActionsPopup from '../ActionsPopup';
import { uploadCustomTrackData } from '@apis';

const UploadCustomTrackPopup = ({
  open,
  handleClose,
  refetchDashboardData,
  isLoadingRefetch,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadedBytes, setLoadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();
  const { user } = useUser();
  const { triggerError } = useError();

  useEffect(() => {
    if (uploadProgress === 100 && !isLoadingRefetch && isUploadSuccess) {
      const timer = setTimeout(() => {
        handleClose();
        refetchDashboardData();
      }, 1000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProgress, isLoadingRefetch, isUploadSuccess]);

  const formatBytes = (bytes) => {
    if (bytes === 0) return '0B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + sizes[i];
  };

  const handleUploadTracksRequest = async (validFiles) => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);

          const response = await uploadCustomTrackData({
            userId: user?.user_id,
            accessToken: idToken,
            tracks: validFiles,
            onUploadProgress: (progressEvent) => {
              const loaded = progressEvent.loaded || 0;
              const total = progressEvent.total || 1;
              const percentCompleted = Math.round((loaded * 100) / total);
              setLoadedBytes(loaded);
              setTotalBytes(total);
              setUploadProgress(percentCompleted);
            },
          });
          // Trigger refetch if the response indicates success
          if (response && response.status_code === 200) {
            setIsUploadSuccess(true);
          } else {
            triggerError(response.status);
            handleClose();
          }
        } catch (error) {
          triggerError('Error during upload:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) =>
      ['audio/mpeg', 'audio/wav'].includes(file.type)
    );

    if (validFiles.length > 0) {
      setUploadProgress(0);
      setLoadedBytes(0);
      setTotalBytes(0);
      await handleUploadTracksRequest(validFiles);
    }
  };

  const openFilePicker = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <ActionsPopup
      open={open}
      handleClose={handleClose}
      handleSubmit={openFilePicker}
      loading={uploadProgress > 0}
      headingText="Upload Custom Tracks"
      loadingText="Uploading..."
      submitBtnText="Select tracks"
      customLoader={
        uploadProgress > 0 ? (
          <Typography
            fontSize="14px"
            fontWeight={500}
            textAlign="center"
            mb="3.3rem"
          >
            {`${uploadProgress}% (${formatBytes(loadedBytes)} / ${formatBytes(
              totalBytes
            )})`}
          </Typography>
        ) : null
      }
    >
      <Typography fontSize="14px" textAlign="center" marginBottom={2}>
        Select custom track files that you want to upload to Vollou.
      </Typography>
      <input
        type="file"
        id="fileInput"
        accept=".mp3, .wav"
        multiple
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </ActionsPopup>
  );
};

export default UploadCustomTrackPopup;
