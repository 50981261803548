import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { format, isToday, isYesterday } from 'date-fns';

import LiveBadge from '../LiveBadge';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

const columnHelper = createColumnHelper();

const columns = [
  {
    id: 'select-col',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        sx={{ padding: 0 }}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
        onClick={(e) => e.stopPropagation()} // Prevent event propagation to row
        sx={{ padding: 0 }}
      />
    ),
    size: 24,
  },
  columnHelper.accessor('start_time', {
    cell: (info) => {
      const live = info.row.original.live;

      const startTime = new Date(info.getValue());

      const relativeTime = isToday(startTime)
        ? 'Today'
        : isYesterday(startTime)
        ? 'Yesterday'
        : null;

      const formattedDate = format(info.getValue(), 'HH:mm dd MMMM yyyy');

      const displayTime = relativeTime
        ? `${format(startTime, 'HH:mm')} ${relativeTime}`
        : formattedDate;

      return (
        <Box
          gap={1}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%', // Ensure it respects the column width
          }}
          display="flex"
          alignItems="center"
        >
          {displayTime}{' '}
          {live && (
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {' '}
              <LiveBadge />{' '}
            </Box>
          )}
        </Box>
      );
    },
    header: () => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        Start Date{' '}
        <Box display="inline-block" sx={{ verticalAlign: 'middle' }}>
          <ArrowDownIcon style={{ marginLeft: '6px' }} />
        </Box>
      </Box>
    ),
    size: 'auto',
  }),
  columnHelper.accessor('location', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue()}
      </Box>
    ),
    header: 'Location',
    size: 'auto',
  }),
  columnHelper.accessor('track_count', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue()}
      </Box>
    ),
    header: 'Tracks',
    size: 60,
  }),
  columnHelper.accessor('genres', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue().length === 0 ? '-' : info.getValue().join(', ')}
      </Box>
    ),
    header: 'Genres',
    size: 'auto',
  }),
  columnHelper.accessor('event_length', {
    cell: (info) => {
      const durationMs = info.getValue();
      const totalMinutes = Math.floor(durationMs / 60000);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${hours > 0 ? hours + 'h ' : ''}${minutes}m`;
    },
    header: 'Length',
    size: 60,
  }),

  columnHelper.display({
    id: 'arrow',
    cell: () => (
      <Box display="flex" justifyContent={'flex-end'}>
        <ArrowForwardIosIcon style={{ color: '#E0E0E0' }} />
      </Box>
    ),
    header: '', // No header for this column
    size: 24,
  }),
];

const EventsList = ({ data, associatedDevice, onSelectionEventChange }) => {
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
  });

  // Calculate fixed and dynamic widths
  const fixedWidth = columns
    .filter((col) => typeof col.size === 'number') // Fixed-width columns
    .reduce((sum, col) => sum + col.size, 0);

  const dynamicColumns = columns.filter((col) => typeof col.size !== 'number');
  const dynamicWidth = dynamicColumns.length
    ? `calc((100% - ${fixedWidth}px) / ${dynamicColumns.length})`
    : 'auto';

  const getSelectedRowIds = () => {
    return Object.keys(rowSelection)
      .filter((key) => rowSelection[key]) // Filter selected rows
      .map((key) => data[key]?.event_id); // Map to event IDs
  };

  useEffect(() => {
    if (rowSelection) {
      const selectedIds = getSelectedRowIds();
      onSelectionEventChange(selectedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  const renderHeader = () => {
    return (
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <TableCell
                key={header.id}
                style={{
                  width:
                    typeof header.column.columnDef.size === 'number'
                      ? `${header.column.columnDef.size}px`
                      : dynamicWidth, // Consistent dynamic width
                }}
                sx={{
                  boxSizing: 'content-box',
                  paddingLeft: index === 0 ? '6rem' : '1.2rem',
                  paddingRight:
                    index === headerGroup.headers.length - 1
                      ? '6rem'
                      : '1.2rem',
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1.6rem',
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  return (
    <TableContainer
      component={Box}
      borderRadius={'0.8rem'}
      pt={'2.4rem'}
      pb={'5rem'}
      bgcolor={'background.section'}
    >
      <Table sx={{ height: '100%', tableLayout: 'fixed' }}>
        {renderHeader()}

        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() =>
                navigate(`/event/${row.original.event_id}`, {
                  state: {
                    eventId: row.original.event_id,
                    associatedDevice,
                  },
                })
              }
              sx={{
                cursor: 'pointer',
                backgroundColor: row.getIsSelected() ? '#10141D' : 'inherit',
              }}
              hover
              selected={row.getIsSelected()}
            >
              {row.getVisibleCells().map((cell, index) => (
                <TableCell
                  key={cell.id}
                  style={{
                    width:
                      typeof cell.column.columnDef.size === 'number'
                        ? `${cell.column.columnDef.size}px`
                        : dynamicWidth, // Consistent dynamic width
                  }}
                  sx={{
                    boxSizing: 'content-box',
                    paddingLeft: index === 0 ? '6rem' : '1.2rem',
                    paddingRight:
                      index === row.getVisibleCells().length - 1
                        ? '6rem'
                        : '1.2rem',
                    paddingTop: '0rem',
                    paddingBottom: '0rem',
                    maxHeight: '40px',
                    height: '40px',

                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '1.6rem !important',
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventsList;
