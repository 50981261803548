import React, { useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';

const ActionsPopup = ({
  open,
  handleClose,
  handleSubmit,
  loading,
  headingText,
  loadingText,
  children,
  submitBtnText,
  disabledSubmit,
  customLoader,
}) => {
  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'unset';
    }

    // Cleanup on unmount
    return () => {
      document.documentElement.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        // Prevent closing when `loading` is true or click comes from the backdrop
        if (!loading && reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      disableScrollLock
      slotProps={{
        backdrop: {
          onClick: (e) => {
            if (!loading) {
              handleClose();
            }
            e.stopPropagation(); // Prevent bubbling
          },
        },
      }}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width={'51.1rem'}
        bgcolor="background.section"
        border="none"
        borderRadius="8px"
        boxShadow={24}
        p={'4rem'}
        sx={{
          transform: 'translate(-50%, -50%)',
          boxShadow: 'none',
        }}
      >
        {loading && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h2"
              textAlign="center"
              mb="3.3rem"
            >
              {loadingText}
            </Typography>

            {customLoader && customLoader}

            <Box
              component="img"
              src="/logo–animated.gif"
              alt="Loading"
              sx={{ width: '120px', height: '120px' }}
            />
          </Box>
        )}

        {!loading && (
          <>
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h2"
              textAlign="center"
            >
              {headingText}
            </Typography>

            {children && <Box paddingTop={'3.3rem'}>{children}</Box>}
            <Box
              mt={'3.8rem'}
              display="flex"
              flexDirection="column"
              gap={'13px'}
            >
              <Button variant="outlined" size="large" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                size="large"
                disabled={loading || disabledSubmit}
                sx={{
                  opacity: disabledSubmit ? '0.4' : 1,
                  color: '#fff !important',
                }}
              >
                {loading ? <CircularProgress size={24} /> : submitBtnText}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ActionsPopup;
