import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { firebaseConfig } from './configs';
import {
  Login,
  GroupDashboard,
  DeviceDashboard,
  EventDashboard,
} from './pages';
import { PrivateRoutes, PublicRoutes } from './utils';
import Layout from './components/Layout';
import theme from './theme';

initializeApp(firebaseConfig);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <Layout>
                <PrivateRoutes />
              </Layout>
            }
          >
            <Route path="/" element={<GroupDashboard />} />
            <Route path="/device" element={<DeviceDashboard />} />
            <Route path="/device/:id" element={<DeviceDashboard />} />
            <Route path="/event" element={<EventDashboard />} />
            <Route path="/event/:id" element={<EventDashboard />} />
          </Route>
          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
