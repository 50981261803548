import React from 'react';
import { Box } from '@mui/material';

export const LoadingLogo = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99999999,
        backdropFilter: 'blur(5px)',
        pointerEvents: 'auto',
      }}
    >
      <Box
        component="img"
        src="/logo–animated.gif"
        alt="Loading"
        sx={{ width: '150px', height: '150px' }}
      />
    </Box>
  );
};
