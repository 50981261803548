import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

const fetchDeviceDashboardData = async (data) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.DEVICE_DASHBOARD_DATA.API,
    data
  );
  return response;
};

export const useDeviceDashboardData = () => {
  const [data, setData] = useState(null);
  const [serverError, setServerError] = useState(null);

  const mutation = useMutation({
    mutationFn: fetchDeviceDashboardData,
    onSuccess: (response) => {
      if (response.status === 200) {
        setData(response.data.data);
        setServerError(null);
      } else {
        setServerError(response.data.status);
      }
    },
    onError: (error) => {
      setServerError(error.message);
    },
  });

  const resetStoredDeviceData = () => {
    setData(null);
  };

  const fetchDashboardData = async (data) => {
    try {
      await mutation.mutateAsync(data);
    } catch (error) {
      console.error('Error fetching device dashboard data:', error);
    }
  };

  return {
    data, // Return the locally stored data
    isLoadingDashboardData: mutation.isLoading, // Use isLoading to track the loading state
    error: mutation.error, // Return the error from the mutation
    fetchDashboardData, // Function to trigger the data fetch
    serverError, // Return the server error
    isRefetching: mutation.isPending,
    resetStoredDeviceData,
  };
};
