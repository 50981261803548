import { Box } from '@mui/material';
import ActionsPopup from '../ActionsPopup';

import TrackItem from './TrackItem';

const RemoveTrack = ({
  open,
  handleClose,
  handleSubmit,
  loading,
  selectedTrackToUpdate,
}) => {
  return (
    <ActionsPopup
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      loading={loading}
      headingText={'Remove Track'}
      loadingText="Removing..."
      submitBtnText={'Remove'}
    >
      {selectedTrackToUpdate && (
        <Box mb="4.3rem">
          <TrackItem
            trackTypeInfo="Track"
            title={selectedTrackToUpdate.title}
            artist={selectedTrackToUpdate.artist}
            coverImage={selectedTrackToUpdate.cover_image}
            duration={selectedTrackToUpdate.time_recorded}
          />
        </Box>
      )}
    </ActionsPopup>
  );
};

export default RemoveTrack;
