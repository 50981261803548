import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import { SectionCard } from '@components/SectionCard';

const TopGenres = ({ data }) => {
  return (
    <SectionCard padding="4rem" height="39rem">
      <ResponsiveContainer width={'85%'} height={350}>
        <BarChart
          layout="vertical"
          width={100}
          height={100}
          data={data}
          margin={{
            top: 20,
            bottom: 5,
            right: 50,
          }}
        >
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tickCount={false}
          />
          <YAxis
            type="category"
            dataKey="genre"
            axisLine={false}
            tick={{ fill: 'white', fontSize: '12px' }}
          />
          <Tooltip
            cursor={{ fill: '#2A3145' }}
            contentStyle={{
              backgroundColor: '#1B2130',
              border: 'none',
              color: '#E0E0E0',
              textAlign: 'center',
            }}
            itemStyle={{ color: '#E0E0E0', fontSize: '10px', fontWeight: 400 }}
            labelStyle={{ color: '#E0E0E0', fontSize: '20px', fontWeight: 700 }}
            filterNull={true}
            isAnimationActive={false}
          />
          <Bar dataKey="value" fill="#FF4A4A" unit="%" barSize={30}>
            <LabelList
              dataKey="value"
              position="right"
              formatter={(value) => value + '%'}
              fontSize={12}
              fill="white"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </SectionCard>
  );
};

export default TopGenres;
