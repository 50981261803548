/**
 * Truncate the text at word boundaries, and add ellipsis if the text exceeds the max length.
 * @param {string} str - The original text string.
 * @param {number} maxLength - The maximum number of characters to display.
 * @return {string} - The truncated string with ellipsis, if needed.
 */
export const truncateText = (str, maxLength) => {
  const ellipsis = '...';

  // If the string length is already within the limit, return the original string
  if (str.length <= maxLength) return str;

  // Find the last space within the limit and truncate there
  const truncated = str.substr(0, str.lastIndexOf(' ', maxLength));

  // Return truncated text with ellipsis
  return truncated + ellipsis;
};
