const ArrowDownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#E0E0E0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.015 3.333v9.334M14.273 8l-5.258 4.667L3.755 8"
    />
  </svg>
);
export default ArrowDownIcon;
