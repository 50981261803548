import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const BadgeContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '33px',
  height: '20px',
  borderRadius: '4px',
  border: '1px solid transparent',
  backgroundImage:
    'linear-gradient(to right, rgba(255, 26, 26, 1), rgba(253, 138, 80, 1))',
  backgroundClip: 'border-box',
  color: '#ffffff',
  fontWeight: '400',
  fontSize: '10px',
  position: 'relative',
  zIndex: 1,

  // Adding an inner background to cover the gradient
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    background: theme.palette.background.section, // Assuming a default background color
    zIndex: -1,
  },
}));

const LiveBadge = () => {
  return <BadgeContainer>Live</BadgeContainer>;
};

export default LiveBadge;
