import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

export const addTrackData = async ({
  userId,
  accessToken,
  eventId,
  newTimeRecorded,
  trackId,
  customTrack,
}) => {
  const response = await axiosClient().post(API_ENDPOINTS.ADD_TRACK_DATA.API, {
    user_id: userId,
    access_token: accessToken,
    event_id: eventId,
    new_time_recorded: newTimeRecorded,
    track_id: trackId,
    custom_track: customTrack
      ? { title: customTrack.title, artist: customTrack.artist }
      : null,
  });
  return response;
};
