import { API_ENDPOINTS } from '@configs';

import { baseURL } from '../axiosClient';

export const uploadCustomTrackData = async ({
  userId,
  accessToken,
  tracks,
  onUploadProgress,
}) => {
  const formData = new FormData();
  formData.append('user_id', userId);
  formData.append('access_token', accessToken);

  tracks.forEach((track) => {
    formData.append('tracks', track);
  });

  const maxAttempts = 5; // Maximum number of retry attempts
  let attempt = 0;

  const exponentialBackoff = (attempt) => {
    const delay = Math.pow(2, attempt) * 1000; // Exponential backoff delay in milliseconds
    return new Promise((resolve) => setTimeout(resolve, delay));
  };

  while (attempt < maxAttempts) {
    try {
      const response = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(
          'POST',
          `${baseURL}${API_ENDPOINTS.UPLOAD_CUSTOM_TRACKS.API}`,
          true
        );
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable && onUploadProgress) {
            onUploadProgress({
              loaded: event.loaded,
              total: event.total,
            });
          }
        };

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve({
              ...JSON.parse(xhr.responseText),
              status_code: xhr.status,
            });
          } else {
            reject(new Error(`Upload failed with status: ${xhr.status}`));
          }
        };

        xhr.onerror = () => reject(new Error('Network error occurred'));
        xhr.ontimeout = () => reject(new Error('Upload timed out'));

        xhr.send(formData); // Send the form data
      });

      return response; // Return the response upon success
    } catch (error) {
      attempt++;
      console.error(`Attempt ${attempt} failed:`, error);

      if (attempt >= maxAttempts) {
        throw error;
      }

      await exponentialBackoff(attempt);
    }
  }
};
