import React from 'react';
import { format } from 'date-fns';

import { Box, Typography } from '@mui/material';

const TrackItem = ({ trackTypeInfo, title, artist, duration, coverImage }) => {
  return (
    <>
      <Typography variant="subtitle1" mb="1.2rem">
        {trackTypeInfo}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '8px',
          marginBottom: '10px',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            component="img"
            src={
              coverImage && coverImage !== '-' && coverImage !== 'undefined'
                ? coverImage
                : '/no-cover-image.png'
            }
            alt={title}
            sx={{
              width: '4.1rem',
              height: '4.1rem',
              borderRadius: '4px',
              marginRight: '1.6rem',
            }}
          />

          <Box
            sx={{
              maxWidth: '221px',
            }}
          >
            <Typography
              variant="subtitle2"
              lineHeight={'1.8rem'}
              mb="0.6rem"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {title}
            </Typography>
            <Typography
              fontWeight={400}
              fontSize="1.4rem"
              color="#E0E0E0"
              lineHeight={'1.6rem'}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              sx={{ opacity: '0.9' }}
            >
              {artist}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography color="#E0E0E0" fontSize="1.6rem">
            {format(new Date(duration), 'HH:mm:ss')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TrackItem;
