import { useMutation } from '@tanstack/react-query';
import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

export const approveTrackData = async ({
  userId,
  accessToken,
  eventId,
  approve,
}) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.APPROVE_TRACK_LIST.API,
    {
      user_id: userId,
      access_token: accessToken,
      event_id: eventId,
      approve,
    }
  );
  return response;
};

export const useApproveTrackDataMutation = (options) => {
  return useMutation({
    mutationFn: (data) => approveTrackData(data),
    ...options,
  });
};
