import * as React from 'react';
import { Box, Typography } from '@mui/material';

const BetaBanner = () => (
  <Box
    sx={{
      width: '100%',
      minHeight: '4rem',
      padding: '0.9rem 1.2rem',
      background: 'linear-gradient(90deg, #FD8A50 0%, #FF1A1A 100%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    }}
  >
    <Box>
      <Typography
        sx={{
          color: 'white',
          fontSize: '1.5rem',
          fontWeight: '500',
        }}
      >
        Welcome to the Vollou Dashboard (Beta), things are subject to change -{' '}
        <a
          href="https://forms.gle/4JEXkMH5wu1NyBev6"
          target="_blank"
          rel="noreferrer"
          style={{ color: 'white' }}
        >
          We’d love to hear any feedback!
        </a>
      </Typography>
    </Box>
  </Box>
);
export default BetaBanner;
