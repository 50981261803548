import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { format, formatDistanceToNowStrict } from 'date-fns';

import ArrowDownIcon from '../Icons/ArrowDownIcon';

const columnHelper = createColumnHelper();

const StatusCell = ({ status }) => {
  const isOnline = status === 'online';
  const color = isOnline ? '#009E10' : '#B31037';
  const text = isOnline ? 'Online' : 'Offline';

  return (
    <Box display="flex" alignItems="center" width={'8rem'}>
      <Box
        component="span"
        sx={{
          width: '1.4rem',
          height: '1.4rem',
          borderRadius: '50%',
          backgroundColor: color,
          marginRight: '1rem',
        }}
      />
      {text}
    </Box>
  );
};

const columns = [
  columnHelper.accessor('status', {
    cell: (info) => {
      return <StatusCell status={info.getValue()} />;
    },
    header: () => (
      <Box display="flex" alignItems="center">
        Status <ArrowDownIcon style={{ marginLeft: '6px' }} />
      </Box>
    ),
    size: 80,
  }),
  columnHelper.accessor('name', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue()}
      </Box>
    ),
    header: 'Name',
    size: 'auto',
  }),
  columnHelper.accessor('location', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue()}
      </Box>
    ),
    header: 'Location',
    size: 'auto',
  }),
  columnHelper.accessor('events', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue()}
      </Box>
    ),
    header: 'Events',
    size: 54,
  }),
  columnHelper.accessor('setup_date', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {format(new Date(info.getValue()), 'dd/MM/yy')}
      </Box>
    ),
    header: 'Setup',
    size: 84,
  }),
  columnHelper.accessor('last_event', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue() !== '-'
          ? formatDistanceToNowStrict(new Date(info.getValue()), {
              addSuffix: true,
            })
          : '-'}
      </Box>
    ),
    header: 'Last Event',
    size: 120,
  }),
  columnHelper.display({
    id: 'arrow',
    cell: () => (
      <Box
        width="2.4rem"
        height="2.4rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ArrowForwardIosIcon />
      </Box>
    ),
    header: '', // No header for this column
    size: 24,
  }),
];

const Devices = ({ data }) => {
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Calculate fixed and dynamic widths
  const fixedWidth = columns
    .filter((col) => typeof col.size === 'number') // Fixed-width columns
    .reduce((sum, col) => sum + col.size, 0);

  const dynamicColumns = columns.filter((col) => typeof col.size !== 'number');
  const dynamicWidth = dynamicColumns.length
    ? `calc((100% - ${fixedWidth}px) / ${dynamicColumns.length})`
    : 'auto';

  const renderHeader = () => {
    return (
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <TableCell
                key={header.id}
                sx={{
                  boxSizing: 'content-box',
                  paddingLeft: index === 0 ? '6rem' : '1.2rem',
                  paddingRight:
                    index === headerGroup.headers.length - 1
                      ? '6rem'
                      : '1.2rem',
                  paddingTop: '1.1rem',
                  paddingBottom: '1.1rem',
                  lineHeight: '18px',

                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                style={{
                  width:
                    typeof header.column.columnDef.size === 'number'
                      ? `${header.column.columnDef.size}px`
                      : dynamicWidth, // Consistent dynamic width
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  const renderBody = () => {
    return (
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.id}
            onClick={() => navigate(`/device/${row.original.device_id}`)}
            sx={{
              cursor: 'pointer',
            }}
            hover
          >
            {row.getVisibleCells().map((cell, index) => (
              <TableCell
                key={cell.id}
                sx={{
                  boxSizing: 'content-box',
                  paddingLeft: index === 0 ? '6rem' : '1.2rem',
                  paddingRight:
                    index === row.getVisibleCells().length - 1
                      ? '6rem'
                      : '1.2rem',
                  paddingTop: '0.8rem',
                  paddingBottom: '0.8rem',

                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                style={{
                  width:
                    typeof cell.column.columnDef.size === 'number'
                      ? `${cell.column.columnDef.size}px`
                      : dynamicWidth, // Consistent dynamic width
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <Box
      borderRadius={'0.8rem'}
      pt={'2.4rem'}
      pb={'5rem'}
      bgcolor={'background.section'}
    >
      <TableContainer>
        <Table sx={{ height: '100%', tableLayout: 'fixed' }}>
          {renderHeader()}
          {renderBody()}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Devices;
