import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import EmptyContent from '../EmptyContent';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

const columnHelper = createColumnHelper();

const trackImageSize = 41;

const TopTracks = ({ tracks }) => {
  if (!tracks || tracks.length === 0) {
    return <EmptyContent text="There are no top tracks found in the group." />;
  }

  const columns = [
    columnHelper.accessor('index', {
      cell: (info) => (
        <Box fontSize="1.6rem" fontWeight={500} width="16px">
          {info.row.index + 1}
          {'.'}
        </Box>
      ),
      header: '#',
      size: 54,
    }),
    columnHelper.accessor('cover_image', {
      cell: (info) => (
        <Box display="flex" style={{ minWidth: `${trackImageSize}px` }}>
          <img
            src={info.getValue() || '/no-cover-image.png'}
            alt="Cover"
            style={{
              width: `${trackImageSize}px`,
              height: `${trackImageSize}px`,
              borderRadius: '4px',
            }}
            onError={(e) => (e.target.src = '/no-cover-image.png')}
          />
        </Box>
      ),
      header: 'Title',
      colSpan: 2, // Specify colspan directly
      size: 57,
    }),
    columnHelper.accessor((row) => `${row.title} - ${row.artist}`, {
      id: 'track',
      cell: (info) => (
        <Box
          style={{
            maxWidth: `100%`,
            overflow: 'hidden',
          }}
        >
          <Typography
            color="#E0E0E0"
            variant="h6"
            noWrap
            lineHeight={'1.8rem'}
            mb="0.6rem"
          >
            {info.row.original.title}
          </Typography>
          <Typography
            variant="body2"
            color="#E0E0E0"
            noWrap
            lineHeight={'1.8rem'}
          >
            {info.row.original.artist}
          </Typography>
        </Box>
      ),
      header: '',
      size: 'auto',
    }),
    columnHelper.accessor('genres', {
      cell: (info) =>
        info.getValue().length > 0 ? info.getValue().join(', ') : '-',
      header: 'Genres',
      size: 'auto',
    }),
    columnHelper.accessor('count', {
      cell: (info) => info.getValue(),
      header: () => (
        <Box display="flex" alignItems="center">
          Count <ArrowDownIcon style={{ marginLeft: '6px' }} />
        </Box>
      ),
      size: 108,
    }),
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const table = useReactTable({
    data: tracks,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer
      component={Box}
      borderRadius="0.8rem"
      pt="1.6rem"
      pb="1rem"
      bgcolor="background.section"
      minHeight="39rem"
      style={{ width: '100%' }}
    >
      <Table sx={{ width: '100%', tableLayout: 'fixed' }}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <TableCell
                  key={header.id}
                  colSpan={header.colSpan} // Use TanStack's colSpan
                  style={{
                    width:
                      typeof header.column.columnDef.size === 'number'
                        ? `${header.column.columnDef.size}px`
                        : 'auto',
                    textAlign: 'left',
                    fontSize: '1.4rem',
                    fontWeight: 500,
                    lineHeight: '16px',
                  }}
                  sx={{
                    paddingLeft: index === 0 ? '3rem' : '0.8rem',
                    paddingRight:
                      index === headerGroup.headers.length - 1
                        ? '3rem'
                        : '0.8rem',
                    paddingTop: '1.4rem',
                    paddingBottom: '1.4rem',
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} hover style={{ border: 'none' }}>
              {row.getVisibleCells().map((cell, index) => (
                <TableCell
                  key={cell.id}
                  colSpan={cell.colSpan} // Use TanStack's colSpan
                  style={{
                    width:
                      typeof cell.column.columnDef.size === 'number'
                        ? `${cell.column.columnDef.size}px`
                        : 'auto',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    fontSize: '1.4rem',
                    fontWeight: 400,
                    lineHeight: '16px',
                  }}
                  sx={{
                    paddingLeft: index === 0 ? '3rem' : '0.8rem',
                    paddingRight:
                      index === row.getVisibleCells().length - 1
                        ? '3rem'
                        : '0.8rem',
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopTracks;
