import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

export const removeTrackData = async ({
  userId,
  accessToken,
  eventId,
  timeRecorded,
  trackId,
}) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.REMOVE_TRACK_DATA.API,
    {
      user_id: userId,
      access_token: accessToken,

      event_id: eventId,
      time_recorded: timeRecorded,
      track_id: trackId,
    }
  );
  return response;
};
