import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Box,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { format, isToday, isYesterday, getYear } from 'date-fns';

import LiveBadge from '../LiveBadge';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('start_time', {
    header: () => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',

          display: 'flex',
        }}
      >
        Start Date{' '}
        <Box display="inline-flex" sx={{ verticalAlign: 'middle' }}>
          <ArrowDownIcon style={{ marginLeft: '6px' }} />
        </Box>
      </Box>
    ),
    cell: (info) => {
      const live = info.row.original.live;
      const startTime = new Date(info.getValue());

      // Get the current year
      const currentYear = getYear(new Date());

      const relativeTime = isToday(startTime)
        ? 'Today'
        : isYesterday(startTime)
        ? 'Yesterday'
        : null;

      // Format time and date without year if event is in the current year
      const isCurrentYear = getYear(startTime) === currentYear;
      const dateWithoutYear = format(startTime, 'HH:mm dd MMM');
      const fullDateWithYear = format(startTime, "HH:mm dd MMM ''yy");

      // Choose appropriate date format
      const formattedDate = isCurrentYear ? dateWithoutYear : fullDateWithYear;

      // Display relative time with time or formatted date
      const displayTime = relativeTime
        ? `${format(startTime, 'HH:mm')} ${relativeTime}`
        : formattedDate;

      return (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          <Box display="flex" alignItems="center">
            <Box
              component="span"
              display="inline-block"
              mr="1rem"
              sx={{
                maxWidth: 'calc(100% - 2rem)',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {displayTime}
            </Box>

            {live && <LiveBadge />}
          </Box>
        </Box>
      );
    },
    size: 125,
  }),
  columnHelper.accessor('device_name', {
    header: 'Device Name',
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue()}
      </Box>
    ),
    size: 'auto',
  }),
  columnHelper.accessor('location', {
    header: 'Location',
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {info.getValue()}
      </Box>
    ),
    size: 'auto',
  }),
  columnHelper.accessor('event_length', {
    header: 'Duration',
    cell: (info) => {
      const totalMinutes = info.getValue() / 60000;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60);

      return (
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {`${hours > 0 ? hours + 'h ' : ''}${minutes}m`}
        </Box>
      );
    },
    size: 62,
  }),

  columnHelper.display({
    id: 'arrow',
    cell: () => (
      <Box display="flex" justifyContent={'flex-end'}>
        <ArrowForwardIosIcon style={{ color: '#E0E0E0' }} />
      </Box>
    ),
    header: '', // No header for this column
    size: 24,
  }),
];

const LatestEvents = ({ data }) => {
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Calculate fixed and dynamic widths
  const fixedWidth = columns
    .filter((col) => typeof col.size === 'number') // Fixed-width columns
    .reduce((sum, col) => sum + col.size, 0);

  const dynamicColumns = columns.filter((col) => typeof col.size !== 'number');
  const dynamicWidth = dynamicColumns.length
    ? `calc((100% - ${fixedWidth}px) / ${dynamicColumns.length})`
    : 'auto';

  return (
    <Box
      sx={{
        bgcolor: 'background.section',
        borderRadius: '0.8rem',
        height: '39rem',
        py: '1.6rem',
      }}
    >
      <TableContainer sx={{ borderRadius: '0.8rem', overflowX: 'hidden' }}>
        <Table sx={{ height: '100%', tableLayout: 'fixed' }}>
          {/* Table Headers */}
          <TableHead>
            <TableRow>
              {table.getHeaderGroups().map((headerGroup) =>
                headerGroup.headers.map((header, index) => (
                  <TableCell
                    key={header.id}
                    sx={{
                      fontWeight: 'bold',
                      boxSizing: 'content-box',
                      paddingLeft: index === 0 ? '3rem' : '0.8rem',
                      paddingRight:
                        index === headerGroup.headers.length - 1
                          ? '3rem'
                          : '0.8rem',
                      paddingTop: '0rem',
                      paddingBottom: '0rem',
                      height: '44px',

                      fontSize: '1.4rem !important',
                      lineHeight: '1.6rem',
                    }}
                    style={{
                      width:
                        typeof header.column.columnDef.size === 'number'
                          ? `${header.column.columnDef.size}px`
                          : dynamicWidth, // Consistent dynamic width
                    }}
                  >
                    <Box
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Box>
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                onClick={() =>
                  navigate(`/event/${row.original.event_id}`, {
                    state: {
                      eventId: row.original.event_id,
                      deviceName: null,
                    },
                  })
                }
                sx={{
                  cursor: 'pointer',
                  height: '40px',
                }}
                hover
              >
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    key={cell.id}
                    sx={{
                      paddingLeft: index === 0 ? '3rem' : '0.8rem',
                      paddingRight:
                        index === row.getVisibleCells().length - 1
                          ? '3rem'
                          : '0.8rem',

                      boxSizing: 'content-box',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      lineHeight: '1.6rem !important',
                    }}
                    style={{
                      width:
                        typeof cell.column.columnDef.size === 'number'
                          ? `${cell.column.columnDef.size}px`
                          : dynamicWidth, // Consistent dynamic width
                    }}
                  >
                    <Box
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LatestEvents;
