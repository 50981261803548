import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

export const deleteCustomTrackData = async ({
  userId,
  accessToken,
  customTrackId,
}) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.DELETE_CUSTOM_TRACK.API,
    {
      user_id: userId,
      access_token: accessToken,
      custom_track_id: customTrackId, //string
    }
  );
  return response;
};
