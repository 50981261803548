import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { Header } from '@components';
import BetaBanner from '../BetaBanner';
import { headerZIndex } from '../../constants/styleConfigs';
import { accountTypes } from '../../constants/accountTypes';
import { useUser } from '@context/UserContext';
import ServerErrorAlert from '../ServerErrorAlert';

const Layout = ({ children }) => {
  const { user, fetchUser, isLoading } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      return;
    }

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      user &&
      user?.account_type === accountTypes.DEVICE &&
      !location.pathname.includes('event')
    ) {
      navigate('/device');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.account_type]);

  return (
    <>
      <Box position="fixed" zIndex={headerZIndex} width="100vw">
        <BetaBanner />
        <Header
          name={user?.username}
          image={user?.profile_image}
          hasLoading={isLoading}
        />
      </Box>

      <main style={{ paddingTop: '136.5px' }}>{children}</main>

      <ServerErrorAlert />
    </>
  );
};

export default Layout;
