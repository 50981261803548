import * as React from 'react';

const TrashIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#E0E0E0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.375 4.25h11.25M5.5 4.25V3a1.25 1.25 0 0 1 1.25-1.25h2.5A1.25 1.25 0 0 1 10.5 3v1.25m1.875 0V13a1.25 1.25 0 0 1-1.25 1.25h-6.25A1.25 1.25 0 0 1 3.625 13V4.25h8.75ZM6.75 7.375v3.75M9.25 7.375v3.75"
    />
  </svg>
);
export default TrashIcon;
