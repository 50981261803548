import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

export const exportEventsData = async ({ userId, accessToken, eventIds }) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.EXPORT_EVENTS_DATA.API,
    { user_id: userId, access_token: accessToken, event_ids: eventIds },
    { responseType: 'blob' }
  );
  return response;
};
