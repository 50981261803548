import React, { useState, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import TrashIcon from '../Icons/TrashIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

const columnHelper = createColumnHelper();

const CustomTracks = ({
  tracks,
  onSelectCustomTrackToDelete,
  deleteCustomTrackInfoText,
}) => {
  const [page, setPage] = useState(1);
  const rowsPerPage = 8;

  const totalRows = tracks.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const paginatedData = useMemo(() => {
    return tracks.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  }, [page, rowsPerPage, tracks]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Name',
        size: 'auto',
      }),
      columnHelper.accessor('date_added', {
        cell: (info) => {
          const date = new Date(info.getValue());
          return date.toLocaleDateString();
        },
        header: () => (
          <Box display="flex" alignItems="center">
            Added <ArrowDownIcon style={{ marginLeft: '6px' }} />
          </Box>
        ),
        size: 63,
      }),
      columnHelper.accessor('file_type', {
        cell: (info) => info.getValue(),
        header: 'Type',
        size: 32,
      }),
      columnHelper.accessor('duration', {
        cell: (info) => {
          const durationMs = info.getValue();
          const totalMinutes = Math.floor(durationMs / 60000);
          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          const seconds = Math.floor((durationMs % 60000) / 1000);

          if (totalMinutes === 0) {
            return `${seconds}s`;
          }

          return `${hours > 0 ? hours + 'h ' : ''}${minutes}m ${seconds}s`;
        },
        header: 'Length',
        size: 45,
      }),

      columnHelper.display({
        id: 'delete',
        cell: ({ row }) => (
          <Tooltip
            title={deleteCustomTrackInfoText}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'background.tooltip',
                  color: 'rgba(255, 255, 255, 0.6)',
                  fontSize: '11px',
                },
              },
            }}
          >
            <span>
              {' '}
              <IconButton
                aria-label="delete"
                sx={{
                  padding: 0,
                  width: '1.8rem',
                  height: '1.8rem',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(16, 20, 29, 0.4)',
                    color: '#fff',
                  },
                }}
                disabled={
                  deleteCustomTrackInfoText || deleteCustomTrackInfoText !== ''
                }
                onClick={() =>
                  onSelectCustomTrackToDelete(row.original.custom_track_id)
                }
              >
                <TrashIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        header: '',
        size: 10,
      }),
    ],
    [onSelectCustomTrackToDelete, deleteCustomTrackInfoText]
  );

  const table = useReactTable({
    data: paginatedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Calculate fixed and dynamic widths
  const fixedWidth = columns
    .filter((col) => typeof col.size === 'number') // Fixed-width columns
    .reduce((sum, col) => sum + col.size, 0);

  const dynamicColumns = columns.filter((col) => typeof col.size !== 'number');
  const dynamicWidth = dynamicColumns.length
    ? `calc((100% - ${fixedWidth}px) / ${dynamicColumns.length})`
    : 'auto';

  const handlePrevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const renderHeader = () => (
    <TableHead>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => (
            <TableCell
              key={header.id}
              style={{
                width:
                  typeof header.column.columnDef.size === 'number'
                    ? `${header.column.columnDef.size}px`
                    : dynamicWidth,
                paddingLeft: index === 0 ? '30px' : '8px',
                paddingRight:
                  index === headerGroup.headers.length - 1 ? '30px' : '8px',
                paddingTop: '14px',
                paddingBottom: '14px',
                fontSize: '1.4rem',
                fontWeight: 500,
                lineHeight: '16px',
              }}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );

  return (
    <TableContainer
      component={Box}
      borderRadius="0.8rem"
      pt="1.6rem"
      pb="1rem"
      bgcolor="background.section"
      minHeight={'39rem'}
      position="relative"
    >
      <Table sx={{ height: '100%' }}>
        {renderHeader()}
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} hover>
              {row.getVisibleCells().map((cell, index) => (
                <TableCell
                  key={cell.id}
                  style={{
                    width:
                      typeof cell.column.columnDef.size === 'number'
                        ? `${cell.column.columnDef.size}px`
                        : dynamicWidth, // Consistent dynamic width
                    paddingLeft: index === 0 ? '30px' : '8px',
                    paddingRight:
                      index === row.getVisibleCells().length - 1
                        ? '30px'
                        : '8px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    fontSize: '1.2rem',
                    lineHeight: '16px',
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        position="absolute"
        bottom="1.6rem"
        right={'3rem'}
      >
        <Typography fontSize="1.2rem" color="#E0E0E0" mr="0.8rem">
          {`${(page - 1) * rowsPerPage + 1} - ${
            page * rowsPerPage > totalRows ? totalRows : page * rowsPerPage
          } of ${totalRows}`}
        </Typography>
        <IconButton
          onClick={handlePrevPage}
          disabled={page === 1}
          aria-label="previous page"
          sx={{
            color: page === 1 ? '#757575 !important' : 'white',
            padding: 0,
            width: '2.4rem',
            height: '2.4rem',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '&:hover': {
              backgroundColor: 'rgba(16, 20, 29, 0.4)',
              color: '#fff',
            },
          }}
        >
          <ArrowBackIos sx={{ fontSize: '1.4rem', marginLeft: '0.4rem' }} />{' '}
        </IconButton>
        <IconButton
          onClick={handleNextPage}
          disabled={page === totalPages}
          aria-label="next page"
          sx={{
            color: page === totalPages ? '#757575 !important' : 'white',
            padding: 0,
            width: '2.4rem',
            height: '2.4rem',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '&:hover': {
              backgroundColor: 'rgba(16, 20, 29, 0.4)',
              color: '#fff',
            },
          }}
        >
          <ArrowForwardIos sx={{ fontSize: '1.4rem' }} />
        </IconButton>
      </Box>
    </TableContainer>
  );
};

export default CustomTracks;
