import { styled } from '@mui/material/styles';

import Switch from '@mui/material/Switch';

const ToggleButton = styled((props) => (
  <Switch focusVisibleClassName="Mui-focusVisible" disableRipple {...props} />
))(({ theme, isShow }) => ({
  width: 42,
  height: 24,
  padding: 0,
  margin: 0,
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        background: 'transparent',
        opacity: 1,
        position: 'relative',
        border: '1px solid transparent',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          borderRadius: 'inherit',
          padding: '1px',
          background: 'linear-gradient(45deg, #FD8A50, #FF1A1A)',
          '-webkit-mask':
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          '-webkit-mask-composite': 'xor',
          'mask-composite': 'exclude',
        },
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    border: `1px solid ${isShow ? 'transparent' : '#757575'}`,
    backgroundColor: 'transparent',
    position: 'relative',
    transition: theme.transitions.create(['background-color', 'border']),
    '&::before': isShow
      ? {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          borderRadius: 'inherit',
          padding: '1px',
          background: 'linear-gradient(45deg, #FD8A50, #FF1A1A)',
          '-webkit-mask':
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          '-webkit-mask-composite': 'xor',
          'mask-composite': 'exclude',
        }
      : null,
  },
}));

export default ToggleButton;
