export const editTrackStatuses = {
  REMOVED: 'removed',
  ADDED: 'added',
  UPDATED: 'updated',
  NONE: 'none',
};

const parseTrackUpdate = (trackUpdated) => {
  // Split the string into parts based on the <br> tags
  const parts = trackUpdated.split('<br>');

  const paragraph = parts[0].trim();

  const listItems = parts
    .slice(1)
    .map((part, index) => <li key={index}>{part.replace('- ', '').trim()}</li>);

  return { paragraph, listItems };
};

export const getMessageForStatusOfEdit = (status, editTrackInfoMessages) => {
  if (!editTrackInfoMessages) {
    return '';
  }

  switch (status) {
    case editTrackStatuses.REMOVED:
      return editTrackInfoMessages.track_removed;
    case editTrackStatuses.ADDED:
      return editTrackInfoMessages.track_added;
    case editTrackStatuses.UPDATED:
      if (editTrackInfoMessages.track_updated) {
        const { paragraph, listItems } = parseTrackUpdate(
          editTrackInfoMessages.track_updated
        );

        return (
          <div>
            <p style={{ margin: '0' }}>{paragraph}</p>
            <ul style={{ margin: '0', paddingLeft: '20px' }}>{listItems}</ul>
          </div>
        );
      }
      return '';
    case editTrackStatuses.NONE:
    default:
      return '';
  }
};
