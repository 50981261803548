import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

export const searchTracksData = async ({
  userId,
  accessToken,
  searchQuery,
}) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.SEARCH_TRACKS_DATA.API,
    { user_id: userId, access_token: accessToken, search_query: searchQuery }
  );
  return response;
};
