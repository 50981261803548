import React, { useState, Fragment } from 'react';
import { Box, FormHelperText, Grid, InputLabel, Snackbar } from '@mui/material';
import {
  getAuth,
  signInWithEmailAndPassword,
  getAdditionalUserInfo,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { Button, Input, LoadingLogo } from '../../components';
import { useUser } from '@context/UserContext';
import {
  AUTH_ERROR,
  EMAIL_ERROR,
  EMAIL_PATTERN,
  PASSWORD_ERROR,
  INVALID_EMAIL_ERROR,
  INVALID_LOGIN_CREDENTIALS,
  WRONG_CREDENTIALS_ERROR,
  IS_MBL_DEVICE,
} from '../../configs';
import { getUser } from '../../apis';
import LoginBg from '../../assets/pngs/login-bg.png';
import LoginBgMbl from '../../assets/pngs/login-bg-mbl.png';

import './index.css';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  open: false,
};

const INITIAL_ERRORS = {
  email: null,
  password: null,
  server: null,
};

export const Login = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { setUser } = useUser();

  const [userData, setUserData] = useState(INITIAL_STATE);
  const [error, setError] = useState(INITIAL_ERRORS);
  const [isLoading, setIsLoading] = useState(false);

  const validateData = () => {
    const { email, password } = userData;
    if (!email || !password || !EMAIL_PATTERN.test(email)) {
      let errors = INITIAL_ERRORS;
      if (!email) {
        errors = { ...errors, email: 'Email cannot be empty' };
      }

      if (email && !EMAIL_PATTERN.test(email)) {
        errors = { ...errors, email: 'Invalid email' };
      }

      if (!password) {
        errors = { ...errors, password: 'Password cannot be empty' };
      }
      console.log('errors: ', errors);
      setError(errors);
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (validateData()) {
      setIsLoading(true);
      try {
        const authRes = await signInWithEmailAndPassword(
          auth,
          userData.email,
          userData.password
        );

        const { isNewUser } = getAdditionalUserInfo(authRes);

        if (!isNewUser) {
          handleLoginSuccess(authRes.user);
        }
      } catch (err) {
        setIsLoading(false);
        if (
          [
            EMAIL_ERROR,
            PASSWORD_ERROR,
            INVALID_EMAIL_ERROR,
            INVALID_LOGIN_CREDENTIALS,
          ].includes(err.message)
        ) {
          setUserData({ ...userData, open: false });

          setError({
            ...error,
            email: WRONG_CREDENTIALS_ERROR,
            password: 'default',
          });
          return;
        }

        setUserData({ ...userData, open: true });

        setError({
          ...error,
          server: AUTH_ERROR,
          email: 'default',
          password: 'default',
        });
      }
    }
  };

  const handleLoginSuccess = async (data) => {
    setUserData({ ...userData, open: false });
    setError(INITIAL_ERRORS);
    const { accessToken, email } = data;
    const currentUser = await fetchUser(accessToken, email);
    if (accessToken && currentUser) {
      // Save user data to user context in client side
      setUser(currentUser);

      if (currentUser.account_type === 'device') {
        navigate('/device');
      }
      if (currentUser.account_type === 'group') {
        navigate('/');
      }
    }
  };

  const fetchUser = async (accessToken, email) => {
    const parseData = {
      access_token: accessToken,
      email,
    };

    const { data, status, responseStatus } = await getUser(parseData);

    if (responseStatus === 200) {
      localStorage.setItem('user_access_token', accessToken);
      localStorage.setItem('user_id', data.user_id);
      setIsLoading(false);
      return data;
    } else {
      setIsLoading(false);
      setUserData({ ...userData, open: true });
      setError({
        ...error,
        server: status,
        email: 'default',
        password: 'default',
      });
      return;
    }
  };

  const handleChangeInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: null, server: null });
  };

  return (
    <Fragment>
      {isLoading && <LoadingLogo />}

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box className="login-bg-wrapper">
            <img
              src={IS_MBL_DEVICE ? LoginBgMbl : LoginBg}
              alt="login-background"
            />
            <h3 className="login-description">
              Connecting Artists, Music, and Fair Royalties.
            </h3>
            <Box className="login-blur-gradient" />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="login-wrapper">
            <Box>
              <h1 className="login-heading">Login</h1>
              <Box component="form" noValidate onSubmit={handleLogin}>
                <Box className="login-inputs-wrapper">
                  <Box className="login-textfield-wrapper">
                    <InputLabel className="login-input-label">Email</InputLabel>
                    <Input
                      type="email"
                      name="email"
                      placeholder="johndoe@email.com"
                      value={userData.email}
                      error={error.email || error.server}
                      onChange={handleChangeInput}
                    />
                    <FormHelperText
                      className={`error-message ${
                        !error.email ? 'visible-text' : ''
                      }`}
                      sx={{ marginBottom: '1.8rem' }}
                    >
                      {!error.server &&
                        error.email !== 'default' &&
                        error?.email}
                    </FormHelperText>
                  </Box>
                  <Box className="login-textfield-wrapper login-password-wrapper">
                    <InputLabel className="login-input-label">
                      Password
                    </InputLabel>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={userData.password}
                      error={error.password || error.server}
                      onChange={handleChangeInput}
                    />
                    <FormHelperText
                      className={`error-message ${
                        !error.password ? 'visible-text' : ''
                      }`}
                      sx={{ marginBottom: '1.8rem' }}
                    >
                      {!error.server &&
                        error.password !== 'default' &&
                        error?.password}
                    </FormHelperText>
                  </Box>
                </Box>
                <Box className="login-btn-wrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    className="login-button"
                  >
                    Login
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="error-alert">
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              autoHideDuration={5000}
              open={userData.open}
              onClose={() => {
                setUserData({ ...userData, open: false });
              }}
              message={error.server}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
