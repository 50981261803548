import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Container, Grid } from '@mui/material';

import { useGroupDashboardData } from '@apis';
import { useDetectOutsideClick } from '@hooks';
import { MIN_DATE_RANGE, FILTERS, FILTERS_CUSTOM_DATE } from '@configs';
import { getDateDifference, getStartOfMonth } from '@utils';
import { useUser } from '@context/UserContext';
import { LoadingLogo } from '@components';

import MetricCount from './components/v2/MetricCount';
import SectionHeading from './components/v2/SectionHeading';
import TopTracks from './components/v2/TopTracks';
import LatestEvents from './components/v2/LatestEvents';
import Devices from './components/v2/Devices';
import DashboardHeader, {
  dashboardTypes,
} from './components/v2/DashboardHeader';

import './index.css';
import EmptyContent from './components/v2/EmptyContent';
import { updateBreadcrumbs } from './components/v2/DashboardBreadcrumb';
import { SectionSkeleton } from '@components/Skeletons/SectionSkeleton';
import ServerErrorAlert from './components/v2/ServerErrorAlert';

const getValidDate = (storedValue, fallback) => {
  const timestamp = Number(storedValue);
  const date = new Date(timestamp);
  return isNaN(date.getTime()) || timestamp === 0 ? fallback : date;
};
const INITIAL_DATE = getValidDate(
  localStorage.getItem('start_date'),
  getDateDifference(3)
);

const END_DATE = getValidDate(localStorage.getItem('end_date'), new Date());
export const GroupDashboard = () => {
  const auth = getAuth();
  const { user, isLoading: isLoadingUserInfo } = useUser();
  const calendarRef = useRef(null);
  const isClickedOutside = useDetectOutsideClick(calendarRef);
  const navigate = useNavigate();

  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const [dateRange, setDateRange] = useState([INITIAL_DATE, END_DATE]);
  const [newDateRange, setNewDateRange] = useState([INITIAL_DATE, END_DATE]);

  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [activeFilter, setActiveFilter] = useState(
    localStorage.getItem('active_filter_name') || 'Last 3 months'
  );
  const breadcrumbData = [{ key: 1, label: 'Home' }];

  const {
    fetchDashboardData,
    isLoadingDashboardData,
    data: dashboardData,
    serverError,
  } = useGroupDashboardData();

  const metricsData = dashboardData?.metrics;
  const topPlayedTracksData = dashboardData?.section_1;
  const latestEventsData = dashboardData?.section_2;
  const devicesData = dashboardData?.section_3;

  useEffect(() => {
    updateBreadcrumbs(breadcrumbData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) return;

    const storedFilter =
      localStorage.getItem('active_filter_name') || 'Last 3 months';

    if (storedFilter !== FILTERS_CUSTOM_DATE) {
      let startDate;
      if (storedFilter === 'This month') {
        startDate = getStartOfMonth();
      } else if (storedFilter === 'Last 3 months') {
        startDate = getDateDifference(3);
      } else if (storedFilter === 'Last 6 months') {
        startDate = getDateDifference(6);
      } else if (storedFilter === 'All Events') {
        startDate = 1704067200000;
      }

      const endDate = new Date();

      // Update state and localStorage to reflect the dynamically recalculated dates
      setDateRange([startDate, endDate]);
      setNewDateRange([startDate, endDate]);

      localStorage.setItem('start_date', new Date(startDate).getTime());
      localStorage.setItem('end_date', endDate.getTime());
      localStorage.setItem('active_filter_name', storedFilter);

      // Fetch updated data with the newly computed dates
      getRefreshedTokenAndUpdateUser(
        new Date(startDate).getTime(),
        endDate.getTime()
      );
    } else {
      // If custom date is selected, rely on previously stored values
      setDateRange([INITIAL_DATE, END_DATE]);
      setNewDateRange([INITIAL_DATE, END_DATE]);

      getRefreshedTokenAndUpdateUser(
        INITIAL_DATE.getTime(),
        END_DATE.getTime()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (dashboardData && !isLoadingDashboardData) {
      setIsLoadingDashboard(false);
      getLastUpdateTime();
    }
  }, [dashboardData, isLoadingDashboardData]);

  useEffect(() => {
    if (isClickedOutside && dateRange !== newDateRange) {
      setDateRange(newDateRange);
    }
    if (isClickedOutside) {
      setIsShowCalendar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedOutside]);

  const getRefreshedTokenAndUpdateUser = (startDate, endDate) => {
    onAuthStateChanged(auth, async (authUser) => {
      setIsLoadingDashboard(true);

      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);

          const params = {
            user_id: user?.user_id,
            access_token: idToken,
            start_date: startDate || new Date(newDateRange[0])?.getTime(),
            end_date: endDate || new Date(newDateRange[1])?.getTime(),
          };
          fetchDashboardData(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const getLastUpdateTime = () => {
    const d = new Date();
    const updateTime = `${d
      .toDateString()
      .split(' ')
      .slice(1)
      .join(' ')} ${d.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })} `;
    setLastUpdateTime(updateTime);
  };

  const handleToggleCalendar = () => {
    setIsShowCalendar(!isShowCalendar);
  };

  const handleSearchBtn = async () => {
    setIsShowCalendar(false);
    setNewDateRange(dateRange);

    const start = new Date(dateRange[0]);
    const end = new Date(dateRange[1]);

    const startUTC = Date.UTC(
      start.getFullYear(),
      start.getMonth(),
      start.getDate()
    );

    // Check if the selected end date is today
    const today = new Date();
    const isToday =
      end.getFullYear() === today.getFullYear() &&
      end.getMonth() === today.getMonth() &&
      end.getDate() === today.getDate();

    const endUTC = isToday
      ? Date.now() // If end date is today, use current timestamp
      : Date.UTC(end.getFullYear(), end.getMonth(), end.getDate() + 1); // Start of next day

    localStorage.setItem('start_date', startUTC);
    localStorage.setItem('end_date', endUTC);
    localStorage.setItem('active_filter_name', FILTERS_CUSTOM_DATE);

    setActiveFilter(FILTERS_CUSTOM_DATE);

    await getRefreshedTokenAndUpdateUser(startUTC, endUTC);
  };
  const onChangeDateRange = (value) => {
    setDateRange(value);
  };

  const onClickDateFilters = async (filter) => {
    let startDate = '';
    if (filter === 'This month') {
      startDate = getStartOfMonth();
    }

    if (filter === 'Last 3 months') {
      startDate = getDateDifference(3);
    }

    if (filter === 'Last 6 months') {
      startDate = getDateDifference(6);
    }

    if (filter === 'All Events') {
      startDate = 1704067200000;
    }

    setActiveFilter(filter);
    localStorage.setItem('start_date', new Date(startDate).getTime());
    localStorage.setItem('end_date', new Date().getTime());
    localStorage.setItem('active_filter_name', filter);
    setDateRange([startDate, new Date()]);
    setNewDateRange([startDate, new Date()]);

    await getRefreshedTokenAndUpdateUser(
      new Date(startDate).getTime(),
      new Date().getTime()
    );
  };

  const calendarProps = {
    isShowCalendar,
    handleToggleCalendar,
    dateRange,
    onChangeDateRange,
    handleSearchBtn,
    calendarRef,
    MIN_DATE_RANGE,
  };

  const filtersProps = {
    FILTERS,
    activeFilter,
    onClickDateFilters,
  };

  return (
    <>
      {(isLoadingDashboard || isLoadingUserInfo) && <LoadingLogo />}

      <Box pb="4rem">
        <Container maxWidth="xl" sx={{ height: '100%' }}>
          <DashboardHeader
            lastUpdateTime={lastUpdateTime}
            calendarProps={calendarProps}
            filtersProps={filtersProps}
            dashboardType={dashboardTypes.group}
            hasFilterOptions
            hasLoading={isLoadingDashboard}
          />

          {serverError && (
            <Box mt="5.4rem">
              <ServerErrorAlert message={serverError} />
            </Box>
          )}

          <Box mt={'54px'}>
            {isLoadingDashboard && <SectionSkeleton height="20rem" />}

            {metricsData && (
              <>
                <SectionHeading
                  headingText={metricsData?.title}
                  tooltipText={metricsData?.info}
                />
                <MetricCount metrics={Object.values(metricsData?.object)} />
              </>
            )}
          </Box>

          <Box mt="5rem">
            <Grid container spacing={'4rem'}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                {isLoadingDashboard && <SectionSkeleton />}

                {topPlayedTracksData && (
                  <>
                    <SectionHeading
                      headingText={topPlayedTracksData?.title}
                      tooltipText={topPlayedTracksData?.info}
                    />
                    <TopTracks tracks={topPlayedTracksData?.object} />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                {isLoadingDashboard && <SectionSkeleton />}

                {latestEventsData && latestEventsData.object.length === 0 && (
                  <EmptyContent text="There are no event in the list." />
                )}

                {!isLoadingDashboard && latestEventsData.object.length > 0 && (
                  <>
                    <SectionHeading
                      headingText={latestEventsData?.title}
                      tooltipText={latestEventsData?.info}
                    />
                    <LatestEvents data={latestEventsData?.object || []} />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box mt="5rem">
            <Grid container>
              <Grid item xs={12} md={12}>
                {isLoadingDashboard && <SectionSkeleton />}

                {devicesData && devicesData.object.length === 0 && (
                  <EmptyContent text="There are no devices found in the group." />
                )}

                {!isLoadingDashboard && devicesData.object.length > 0 && (
                  <>
                    <SectionHeading
                      headingText={devicesData?.title}
                      tooltipText={devicesData?.info}
                    />
                    <Devices data={devicesData?.object || []} />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};
