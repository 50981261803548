import { Typography } from '@mui/material';
import ActionsPopup from '../ActionsPopup';

const UndoTrackEditPopup = ({ open, handleClose, handleSubmit, loading }) => {
  return (
    <ActionsPopup
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      loading={loading}
      headingText={'Undo track edit'}
      loadingText="Undoing..."
      submitBtnText={'Undo edit'}
    >
      <Typography align="center" fontSize="1.4rem">
        After you sure? This cannot be undone.
      </Typography>
    </ActionsPopup>
  );
};

export default UndoTrackEditPopup;
