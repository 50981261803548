export const API_ENDPOINTS = {
  USER: {
    API: '/dashboard/getUser',
  },
  GET_EVENTS: {
    API: '/dashboard/event/getAllEvents',
  },
  EVENTS_SEARCH: {
    API: '/dashboard/event/searchEvents',
  },
  GROUP_DASHBOARD_DATA: {
    API: '/dashboard/getGroupDashboard',
  },
  DEVICE_DASHBOARD_DATA: {
    API: '/dashboard/getDeviceDashboard',
  },
  EVENT_DASHBOARD_DATA: {
    API: '/dashboard/getEventDashboard',
  },
  EXPORT_EVENTS_DATA: {
    API: '/dashboard/exportEvents',
  },
  SEARCH_TRACKS_DATA: {
    API: '/dashboard/searchTracks',
  },
  REMOVE_TRACK_DATA: {
    API: '/dashboard/removeTrack',
  },
  UPDATE_TRACK_DATA: {
    API: '/dashboard/updateTrack',
  },
  ADD_TRACK_DATA: {
    API: '/dashboard/addTrack',
  },
  UNDO_TRACK_EDIT_DATA: {
    API: '/dashboard/undoTrackEdit',
  },
  APPROVE_TRACK_LIST: {
    API: '/dashboard/approveTrackList',
  },
  UPLOAD_CUSTOM_TRACKS: {
    API: '/dashboard/uploadCustomTracks',
  },
  DELETE_CUSTOM_TRACK: {
    API: '/dashboard/deleteCustomTrack',
  },
};
