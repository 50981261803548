import { Box, Button, Typography } from '@mui/material';

const ServerErrorAlert = ({ message }) => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '65rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        bgcolor: 'background.section',
        color: '#ffffff',
        borderRadius: '8px',
      }}
    >
      <Typography mb="2rem" fontSize="3.2rem" fontWeight={500}>
        Failed to collect dashboard data
      </Typography>
      <Typography mb="3.2rem" fontSize="2rem" fontWeight={500} color="#757575">
        {message}
      </Typography>
      <Button variant="outlined" onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </Box>
  );
};

export default ServerErrorAlert;
